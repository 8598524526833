/*eslint no-process-env:0*/
const { version } = require('../../../package.json');

let googleAPIKeys = {
    maps:
        process.env.NODE_ENV === 'production'
            ? 'AIzaSyAycO-sU9d4v4j7lJBmydaelSmzpirIYXg'
            : 'AIzaSyBW-wuFGTjSHk_gg20p9Fsvz9vQ6RAIN5E',
};

let cognito = {
    region: process.env.COGNITO_REGION || 'eu-west-3',
    userPoolId: process.env.COGNITO_POOL_ID || 'eu-west-3_dIkSeqZFP',
    userPoolWebClientId:
        process.env.COGNITO_CLIENT_ID || 'jch23bvujt3rtmdmhqi219hie',
    oauth: {
        domain:
            (process.env.COGNITO_DOMAIN &&
                process.env.COGNITO_DOMAIN !== 'undefined') ||
            'nokey-dev.auth.eu-west-3.amazoncognito.com',
        scope: ['email', 'openid'],
        redirectSignIn: `${
            process.env.COGNITO_CALLBACK_DOMAIN || 'http://localhost:3000'
        }/acc/home`,
        redirectSignOut: `${
            process.env.COGNITO_CALLBACK_DOMAIN || 'http://localhost:3000'
        }/cognito/logout`,
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain:
            process.env.COOKIE_DOMAIN ||
            (typeof window !== 'undefined'
                ? window.location?.hostname
                : 'localhost') ||
            'localhost',
        // OPTIONAL - Cookie path
        path: '/',
        // OPTIONAL - Cookie expiration in days
        expires: 60,
        // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
        sameSite: 'strict',
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        secure: false,
    },
    tokenExpiry: 1000 * 60 * 5,
};

let cognitoTenants = process.env.COGNITO_TENANTS
    ? process.env.COGNITO_TENANTS.split(',')
    : ['AzureADTest'];

const authenticationProvider = process.env.AUTH_PROVIDER || 'cognito';

const EventTypes = [
    'BLEConnected',
    'DoorLocked',
    'DoorUnlocked',
    'DoorOpened',
    'DoorClosed',
    'DoorForcedOpen',
    'Armed',
    'PartialArmed',
    'Disarmed',
    'PartialDisarmed',
    'UnitTamper', // Maybe later
    'LockTamper', // Maybe later
    'Duress',
    'DoorLocking',
    'DoorUnlocking',
    'Arming',
    'Disarming',
    // New events
    'DoorSecured',
    'LockForced',
    'LockLatched',
];

const AuditEventTypes = [
    'Site Create',
    'Site Update',
    'Site Delete',
    'Schedule Create',
    'Schedule Update',
    'Schedule Delete',
    'Contact Create',
    'Contact Update',
    'Account Create',
    'Account Update',
    'Account Enforce MFA',
    'Unit Update',
    'Unit Move Account',
    'Rule Delete',
    'Rule Create',
    'Rule Update',
    'User Create',
    'User Update',
    'User Register Device',
    'User Add to Account',
    'User Remove from Account',
    'User Delete',
    'Login',
    'No-Comms',
    'Tenant Delete',
    'Tenant Create',
    'Tenant Update',
];

const AllEventTypes = EventTypes.concat(AuditEventTypes);

export { version };
export { googleAPIKeys };
export { EventTypes };
export { AuditEventTypes };
export { AllEventTypes };
export { cognito };
export { cognitoTenants };
export { authenticationProvider };
export const env = process.env.NODE_ENV;
export const port = process.env.PORT || 9000;
// List of user roles
export const userRoles = [
    'appUser',
    'accessManager',
    'installer',
    'accountManager',
    'admin',
];
export const policyUpdated = {
    privacy: new Date('2021-06-28T00:00:00.000Z'),
    disclaimer: new Date('2021-06-28T00:00:00.000Z'),
    terms: new Date('2021-06-28T00:00:00.000Z'),
};

export default {
    env,
    port,
    userRoles,
    policyUpdated,
    version,
    googleAPIKeys,
    EventTypes,
    cognito,
    authenticationProvider,
    cognitoTenants
};
