// @flow
import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
//Re-export
import {
    AllEventTypes,
    AuditEventTypes,
    EventTypes,
} from '../app/app.constants';
import type { EventFrontend as Event } from 'server/api/event/event';
export type { EventFrontend as Event } from 'server/api/event/event';

@Injectable()
export class EventService extends BaseService<Event> {
    modelName = 'events';

    constructor(injector: Injector) {
        super(injector);
    }
}
export { AllEventTypes, AuditEventTypes, EventTypes };
