/** Angular Imports */
import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, RouterStateSnapshot } from '@angular/router';

/** Modules Imports */
import { find, isArray, mergeWith, cloneDeep } from 'lodash';
import { lastValueFrom, Subscription, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

/** App Modules */
import { safeCb } from '../util';
import {
    authenticationProvider,
    userRoles,
    version,
} from 'client/app/app.constants';

/** Services Imports */
import { SocketService } from '../socket/socket.service';
import { UserService } from 'client/services/user.service';
import { CognitoService } from './cognito.service';
import { UserIdleService } from 'angular-user-idle';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

/** Components Imports */
import { defineAbilityForAccount } from './ability-builder';
import { IdleModal } from '../idle/idle.modal.component';

/** Type Imports */
import type { AccountStub } from 'client/services/account.service';
import type { User } from 'client/services/user.service';

// *******************************************************************************
//

export interface LoginOptions {
    type: 'CustomFederated' | 'Federated' | 'SRP' | 'Local';
    email?: string;
    password?: string;
    provider?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    _currentUser: User;
    @Output() currentUserChanged = new EventEmitter(true);
    userRoles = userRoles || [];
    userPromise;
    currentAccount: AccountStub;
    isRegistered = false;
    abilityRules;
    subscription: Subscription;
    idleTimerObservable: Observable<number>;
    idleModal: NgbModalRef;
    constructor(
        private http: HttpClient,
        private userService: UserService,
        private router: Router,
        private socketService: SocketService,
        private cognitoService: CognitoService,
        private idleService: UserIdleService,
        private modalService: NgbModal
    ) {
        this.idleTimerObservable = idleService.onTimerStart().pipe(share());
        this.idleTimerObservable.subscribe((value) => {
            if (value === 1) {
                this.idleModal = modalService.open(IdleModal, {
                    backdrop: 'static',
                });
            }
        });
        idleService.onTimeout().subscribe(() => {
            console.log('Timeout!!! User idle, so log them out.');
            this.logout();
        });
    }

    /**
     * Check if userRole is >= role
     * @param {String} userRole - role of current user
     * @param {String} role - role to check against
     */
    static hasRole(userRole, role) {
        return userRoles.indexOf(userRole) >= userRoles.indexOf(role);
    }

    get currentUser() {
        return this._currentUser;
    }

    set currentUser(user) {
        this._currentUser = user;
        this.currentUserChanged.emit(user);
    }

    async login(options: LoginOptions): Promise<any> {
        switch (options.type) {
            case 'Local':
                return this.localLogin({
                    email: options.email,
                    password: options.password,
                });
            case 'SRP':
            case 'CustomFederated':
                return this.cognitoService.signIn(options);
            default:
                break;
        }
    }

    /**
     * Authenticate user and save token
     *
     * @param  {Object}   user     - login info
     * @param  {Function} [callback] - function(error, user)
     * @return {Promise}
     */
    localLogin({ email, password }, callback?) {
        return lastValueFrom(
            this.http.post('/auth/local', {
                email,
                password,
            })
        )
            .then((res: { mfa: { enrolled: boolean }; verified: boolean }) => {
                if (res.mfa) {
                    return {
                        mfaRequired: true,
                        mfa: res.mfa,
                        verified: res.verified,
                    };
                } else {
                    return this.getCurrentUser();
                }
            })
            .then((data: User | any) => {
                if (data.mfaRequired) {
                    return data;
                } else {
                    localStorage.setItem('user', JSON.stringify(data));
                    safeCb(callback)(null, data);
                    return data;
                }
            })
            .catch((err) => {
                this.logout();
                safeCb(callback)(err);
                return Promise.reject(err);
            });
    }
    /**
     * Authenticate user and save token using mfa
     *
     * @param  {Object}   user     - login info
     * @return {Promise}
     */
    mfaLogin({ code }) {
        return lastValueFrom(
            this.http.post('/auth/mfa', {
                code,
            })
        )
            .then((res: { mfa: any }) => {
                if (res.mfa) {
                    return { mfaRequired: true, mfa: res.mfa };
                } else {
                    return this.getCurrentUser();
                }
            })
            .then((data: User | any) => {
                if (data.mfaRequired) {
                    return data;
                } else {
                    localStorage.setItem('user', JSON.stringify(data));
                    return data;
                }
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    cognitoLogin({ options }) {}

    /**
     * Delete access token and user info
     * @return {Promise}
     */
    logout(returnUrl: string = undefined) {
        this.unregister();
        this.idleService.stopWatching();
        this.idleModal?.dismiss();
        if (this.isLoggedInSync()) {
            let promise;
            if (authenticationProvider === 'cognito') {
                promise = this.cognitoService.Auth.signOut();
            } else {
                promise = lastValueFrom(this.http.post('/auth/logout', {}));
            }
            return promise
                .then((res: any) => {
                    this.socketService.disconnect();
                    localStorage.removeItem('user');
                    this.currentUser = undefined;
                    this.router.navigate(['/authentication/login'], {
                        queryParams: { returnUrl },
                    });
                    return;
                })
                .catch((err) => {
                    console.error(`Logout failed: `, err);
                    return Promise.reject(err);
                });
        }
        return this.router.navigate(['/authentication/login'], {
            queryParams: { returnUrl },
        });
    }

    /**
     * Create a new user
     * This should be moved to the user service
     *
     * @param  {Object}   user     - user info
     * @param  {Function} callback - optional, function(error, user)
     * @return {Promise}
     */
    //createUser(user, callback) {
    //return this.userService
    //.save(user)
    //.toPromise()
    //.then((data) => {
    //localStorage.setItem('id_token', data.token);
    //return this.userService.get().toPromise();
    //})
    //.then((_user: User) => {
    //this.currentUser = _user;
    //return safeCb(callback)(null, _user);
    //})
    //.catch((err) => {
    //this.logout();
    //safeCb(callback)(err);
    //return Promise.reject(err);
    //});
    //}

    /**
     * Change password
     *
     * @param  {String}   oldPassword
     * @param  {String}   newPassword
     * @param  {Function} [callback] - function(error, user)
     * @return {Promise}
     */
    changePassword(oldPassword: string, newPassword: string): Promise<void> {
        return lastValueFrom(
            this.userService.changePassword(
                this.currentUser._id,
                oldPassword,
                newPassword
            )
        );
    }

    /**
     * Gets all available info on a user
     *
     * @return {Promise}
     */
    getCurrentUser() {
        const self = this;
        if (self.currentUser && self.currentUser._id) {
            return Promise.resolve(self.currentUser);
        } else {
            return lastValueFrom(self.userService.get()).then((user: User) => {
                self.currentUser = user;
                const idle = user.idleTime || 60 * 5;
                self.idleService.stopWatching();
                self.idleService.setConfigValues({ idle, timeout: 60 });
                self.idleService.stopTimer();
                self.idleService.resetTimer();
                if (!user.hyper) self.idleService.startWatching();
                return user;
            });
        }
    }

    /**
     * Gets all available info on a user
     *
     * @return {Object}
     */
    getCurrentUserSync() {
        return this.currentUser;
    }

    /**
     * Checks if user is logged in
     * @param {function} [callback]
     * @returns {Promise}
     */
    isLoggedIn() {
        return this.getCurrentUser()
            .then((user) => {
                if (user && user._id) {
                    return user;
                } else {
                    return false;
                }
            })
            .catch((err) => {
                return false;
            });
    }

    /**
     * Checks if user is logged in
     * @returns {Boolean}
     */
    isLoggedInSync() {
        return !!this.currentUser?._id;
    }

    /**
     * Check if a user is an admin
     *
     * @param  {Function|*} [callback] - optional, function(is)
     * @return {Promise}
     */
    isAdmin(callback?) {
        return this.getCurrentUser().then((user) => {
            const is = user.role === 'admin';
            safeCb(callback)(is);
            return is;
        });
    }

    isAdminSync() {
        return this.currentUser.role === 'admin';
    }

    getVersion() {
        return version;
    }

    setCurrentAccount(
        accountId?: string,
        state?: RouterStateSnapshot
    ): Promise<AccountStub> {
        const self = this;
        const oldAccount = cloneDeep(self.currentAccount);
        return self.getCurrentUser().then((user) => {
            let newAccount: AccountStub;
            if (accountId && find(user.accounts, ['ref', accountId])) {
                newAccount = find(user.accounts, ['ref', accountId]);
            } else if (
                localStorage.getItem('currentAccount') &&
                find(user.accounts, [
                    'ref',
                    localStorage.getItem('currentAccount'),
                ])
            ) {
                //Get account from url
                newAccount = find(user.accounts, [
                    'ref',
                    localStorage.getItem('currentAccount'),
                ]);
            } else if (user.accounts && user.accounts.length > 0) {
                //Take first account
                newAccount = user.accounts[0];
            }
            if (newAccount) {
                self.currentAccount = newAccount;
                localStorage.setItem('currentAccount', newAccount.ref);

                //Check if account changed and reload
                if (newAccount.ref != accountId) {
                    if (state) {
                        const newUrl = state.url.replace(
                            accountId,
                            newAccount.ref
                        );
                        this.router.navigateByUrl(newUrl);
                    }
                }
                this.abilityRules = defineAbilityForAccount(
                    user,
                    self.currentAccount
                );

                return self.currentAccount;
            } else {
                console.error('User has no accounts');
                //Redirect to account registration?
                return null;
            }
        });
    }

    registerSelf() {
        if (this.currentUser && !this.isRegistered) {
            this.subscription = this.userService
                .register(
                    undefined,
                    { addCreated: false },
                    `user:${this.currentAccount.ref}:${this.currentUser._id}`
                )
                .subscribe((event) => {
                    if (event.item._id === this.currentUser._id) {
                        mergeWith(
                            this.currentUser,
                            event.item,
                            (objValue, srcValue) => {
                                if (isArray(objValue)) {
                                    return srcValue;
                                }
                            }
                        );
                    }
                });
            this.isRegistered = true;
        }
    }
    unregister() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.isRegistered = false;
        }
    }
}
