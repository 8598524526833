// @flow
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService, ExportOptions, BaseQuery } from './base.service';

import type { SiteFrontend as Site } from 'server/api/site/site';
export type {
    SiteFrontend as Site,
    DoorFrontend as Door,
} from 'server/api/site/site';

@Injectable()
export class SiteService extends BaseService<Site> {
    modelName = 'sites';
    constructor(injector: Injector) {
        super(injector);
    }

    exportDoorData(
        options: ExportOptions,
        query?: BaseQuery,
        allAccounts = false
    ): Observable<Blob> {
        let params: HttpParams;
        if (query) {
            params = this.buildParams(query);
        }
        let contentType = 'application/json';
        switch (options.format) {
            case 'csv':
                contentType = 'text/csv';
                break;
            case 'xlsx':
                contentType =
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                break;

            default:
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: contentType,
        });

        if (allAccounts) {
            headers = headers.append('x-js-all-accounts', 'true');
        }
        return this.http.post<Blob>(
            `/api/${this.modelName}/doors/export`,
            options,
            {
                params,
                headers,
                responseType: 'blob' as 'json',
            }
        );
    }
}
